/*Global css*/
@font-face {
  font-family: LTSaeada-Bold;
  src: url('fonts/LTSaeada-Bold.otf') format('opentype');
}

@font-face {
  font-family: LTSaeada-ExtraLight;
  src: url('fonts/LTSaeada-ExtraLight.otf') format('opentype');
}

@font-face {
  font-family: LTSaeada-Light;
  src: url('fonts/LTSaeada-Light.otf') format('opentype');
}

@font-face {
  font-family: LTSaeada-Regular;
  src: url('fonts/LTSaeada-Regular.otf') format('opentype');
}

@font-face {
  font-family: LTSaeada-Medium;
  src: url('fonts/LTSaeada-Medium.otf') format('opentype');
}

@font-face {
  font-family: LTSaeada-SemiBold;
  src: url('fonts/LTSaeada-SemiBold.otf') format('opentype');
}


h1, h2, h3, h4, h5, h6
{
  font-family: LTSaeada-Regular;
}

span, p
{
  font-family: LTSaeada-Light;
}